import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import C4e from './images/c4e.png';
import './Appbar.css';
import { Link } from 'react-router-dom';

export default function Appbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="small" color="inherit">
        <Link to='/contact'>
          <Badge color="error">
            Contact
          </Badge>
          </Link>
        </IconButton>
      </MenuItem>
      <MenuItem>
        <IconButton size="small" color="inherit">
          <a href='https://avinya.care4edu.com/#/institutionalSignIn'>
          <Badge color="error">
           Login
          </Badge>
          </a>
        </IconButton>
      </MenuItem>
      <MenuItem>
        <IconButton size="small" color="inherit">
          <Link to='/services'>
          <Badge color="error">
            Services
          </Badge>
          </Link>
        </IconButton>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          style={{
        
            color: isScrolled ? "black" : "Blue",
            backgroundColor: isScrolled ? "white" : "white",
            // opacity: isScrolled ?"0.9":"1",
            marginTop: isScrolled ? 0 : 10,
            marginRight: isScrolled ? 0 : 10,
            marginLeft: isScrolled ? 0 : 10,
            transition: "all 0.4s ease",
          }}
          elevation={0}
        >
          <Toolbar>
          <Link to='/'>
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 1 }}
            >
              <img src={C4e} alt='logo' style={{ height:isScrolled? 50:50, backgroundColor: "white", width: isScrolled? 50:50 }} />
            </IconButton></Link>
           <Link to='/' style={{textDecoration:"none"}}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' }, fontFamily: "Roboto slab", fontWeight: "bolder", fontSize: "25px" }}
            >
              Care4edu
            </Typography></Link>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to='/contact'>
              <IconButton size="small" color="inherit" style={{ fontFamily: "Roboto slab", fontWeight: "bolder", fontSize: "20px" }}>
                Contact
              </IconButton></Link>
              {/* <Box sx={{ xs: 'none', mx: 2 }} />
              <Link to='/about'>
              <IconButton
                size="small"
                color="inherit"
                style={{ fontFamily: "Roboto slab", fontWeight: "bolder", fontSize: "20px" }}
              >
                About Us
              </IconButton></Link>
              <Box sx={{ xs: 'none', mx: 2 }} /> */}
              <Box sx={{ xs: 'none', mx: 2 }}/>
              <Link to='/services'>
              <IconButton
                size="small"
                color="inherit"
                style={{ fontFamily: "Roboto slab", fontWeight: "bolder", fontSize: "20px" }}
              >
                Services 
              </IconButton></Link>
              <Box sx={{ xs: 'none', mx: 2 }}/>
              <a href='https://avinya.care4edu.com/#/institutionalSignIn'>
              <IconButton
                size="small"
                color="inherit"
                style={{ fontFamily: "Roboto slab", fontWeight: "bolder", fontSize: "20px" }}
              >
               Login 
              </IconButton></a>
             
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
}
