import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Compnents/Home/HomePage';
import Appbar from './Compnents/Appbar';
import Services from './Compnents/Services/Services';
import ContactUs from './Compnents/Contact.jsx/ContactUs';


function App() {
  return (
    <Router>
      <Appbar/>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/services' element={<Services />} />
        <Route path='/contact' element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;
