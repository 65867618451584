import React, { useEffect } from "react";
import { Grid, Typography, Container } from "@mui/material";
import Phone from "../images/Phone.svg";
import Reactjs from "../images/ReactJS.jpg";
import Design from "../images/Design.png";
import Coding from "../images/Coding.svg";
import flutter from "../images/flutter.png";
import Spring from "../images/springboot.png";
import Aos from "aos";
import 'aos/dist/aos.css';
import useMediaQuery from '@mui/material/useMediaQuery';

const Services = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    Aos.init({ delay: 100 });
  }, []);

  return (
    <div className="cadvin" style={{ fontFamily: "Roboto Slab" }}>
      <br />
      <Typography
        variant="h4"
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#0C8599"}
        style={{ fontFamily: "Roboto Slab" }}
      >
        Our Services
      </Typography>
      <div className="images">
        <div style={{ margin: "20px" }}>
          <Container>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: isSmallScreen ? "center" : "flex-end",
                  alignItems: "center",
                }}
              >
                <img src={Phone} width="100%" alt="phone1" height="270" style={{ objectFit: "contain" }} data-aos="zoom-in" />
              </Grid>
              <Grid item xs={12} md={8}>
                <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "Roboto Slab" }}
                    color={"#0C8599"}
                  >
                    Android App
                  </Typography>
                  <Typography style={{ fontFamily: "Roboto Slab", fontSize: "1.2rem" }}>
                    We specialize in creating innovative and user-friendly Android applications tailored to meet the unique needs of our clients. Our experienced team of developers is dedicated to delivering high-quality, scalable, and secure mobile solutions that enhance user experience and drive business growth. <br /><br />
                    <span style={{ color: "red" }}>Tools Used</span><br /><img src={flutter} alt="Tool" style={{ height: 50, width: 90, objectFit: "contain" }} data-aos="zoom-in" /> <img src={Spring} alt="Tool" style={{ height: 40, width: 80, objectFit: "contain" }} data-aos="zoom-in" />
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: isSmallScreen ? "center" : "flex-end",
                  alignItems: "center",
                }}
                data-aos="zoom-in"
              >
                <img src={Coding} width="100%" alt="Tool" height="270" style={{ objectFit: "contain" }} data-aos="zoom-in" />
              </Grid>
              <Grid item xs={12} md={8}>
                <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "Roboto Slab" }}
                    color={"#0C8599"}
                  >
                    Web Development
                  </Typography>
                  <br />
                  <Typography style={{ fontFamily: "Roboto Slab", fontSize: "1.2rem" }}>
                    We are experts in crafting dynamic, responsive, and visually appealing websites that cater to the diverse needs of our clients. Our skilled team of web developers is committed to building high-performance websites that enhance user engagement and drive business success. <br /><br />
                    <span style={{ color: "red" }}>Tools Used</span><br /><img src={Reactjs} alt="Tool" style={{ height: 50, width: 90, objectFit: "contain" }} data-aos="zoom-in" /> <img src={Spring} alt="Tool" style={{ height: 40, width: 80, objectFit: "contain" }} data-aos="zoom-in" />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <br />
        <div style={{ margin: "20px" }}>
          <Container>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: isSmallScreen ? "center" : "flex-end",
                  alignItems: "center",
                }}
              >
                <img src={Design} width="100%" alt="asset" height="250" style={{ objectFit: "contain" }} data-aos="zoom-in" />
              </Grid>
              <Grid item xs={12} md={8}>
                <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "Roboto Slab" }}
                    color={"#0C8599"}
                  >
                    Asset Management
                  </Typography>
                  <br />
                  <Typography style={{ fontFamily: "Roboto Slab", fontSize: "1.2rem" }}>
                    We offer comprehensive asset management solutions designed to help organizations efficiently track, manage, and optimize their assets. Our advanced asset management system provides real-time insights, enabling you to make informed decisions and improve operational efficiency.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Services;
