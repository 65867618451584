import { Paper, Card, CardContent, Typography, TextField, Button, Grid } from '@mui/material';
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useMediaQuery from '@mui/material/useMediaQuery';
import contact from '../images/contact.jpg'

export default function ContactUs() {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop:isSmallScreen?50:0 }}>
      <Paper 
        elevation={2} 
        style={{  
          padding: 20,
          margin: '10%', 
          display: 'flex', 
          background:`url(${contact})`,
          flexDirection: isSmallScreen ? 'column' : 'row', 
          justifyContent: 'space-between',
        
        }}
      >
        <div style={{ flex: '0 0 30%', marginRight: isSmallScreen ? 0 : 20, marginBottom: isSmallScreen ? 20 : 0 }}>
          <Card style={{marginTop:20,}}>
            <h3 style={{ fontFamily:"Roboto Slab", color:"" }}>Contact Us</h3>
            <CardContent style={{ display: "flex" }}>
              <PhoneIcon fontSize='small' />&nbsp;&nbsp;<Typography variant="p" style={{fontFamily:"Roboto Slab"}}> 9535352376</Typography>
            </CardContent>
            <CardContent style={{ display: "flex" }}>
              <EmailIcon fontSize='small' />&nbsp;&nbsp;<Typography variant="p"  style={{fontFamily:"Roboto Slab"}}> info@care4edu.com</Typography>
            </CardContent>
            <CardContent style={{ display: "flex" }}>
              <LocationOnIcon fontSize='small' /><Typography variant="p"  style={{fontFamily:"Roboto Slab"}}> Doddabommasandra Post,
                <br />
                Vidyaranyapura, Bengaluru – 5600097
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, marginLeft: isSmallScreen ? 0 : 20 }}>
          <h4 style={{ marginLeft: 20, fontFamily:"Roboto Slab"}}>Fill Up The Form and Get a call back from us</h4>
          <form noValidate style={{ textAlign: "center", padding: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Phone"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Message"
                  variant="outlined"
                  multiline
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 20, marginBottom: 10, width:30}}
            >
              Submit
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  );
}
