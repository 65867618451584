import React, { useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import image from '../images/colab.png'
import Aos from 'aos';
import 'aos/dist/aos.css'
import Services from '../Services/Services';
import shikshak from '../images/Shiskspro.jpg'
import bnb from '../images/logo new.png'
import asat from '../images/Imag.png'
import avinya from '../images/Image.png'
import background from '../images/bg6.jpg'
import ContactUs from '../Contact.jsx/ContactUs';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';

export default function HomePage() {
const navigate=useNavigate()
  const handlenavigate=(()=>{
   navigate('/contact')
  })
  useEffect(() => {
    Aos.init({ delay: 100 })
  }, [])
  return (
    <>
      <div style={{
        marginTop: '50px',
        alignItems: 'center',
        padding: '20px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <img src={image} alt='img' style={{ height: 200, width: 200 }} data-aos="zoom-in-down" />
          <Grid data-aos="zoom-in">
            <Typography style={{ fontSize: 30, fontWeight: "bolder", color: "blue" }} >
              Empowering Your Digital Presence
            </Typography>
            <p >Innovative Solutions for Websites, Android Apps, and Asset Management.</p>
            <Button variant='contained' size='small' onClick={handlenavigate} >Contact Us</Button>
          </Grid>
        </div>
      </div>


      <Services />
      <div style={{ textAlign: "center" }}>

        <h3 style={{ color: "blue", fontFamily: "Roboto Slab", marginTop: 20 }}>Our Products</h3>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 4,
              width: 118,
              height: 110,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <a href="https://play.google.com/store/apps/details?id=com.shikshapro.care4edu.release" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <Paper elevation={24} data-aos="zoom-in-down">
              <img src={shikshak} style={{ width: 100, height: 100, objectFit: 'contain' }} alt="shikshak" />
              <Typography style={{ color: "#BF2D8E", fontWeight:"bold" }}>Shikshak Pro</Typography>

            </Paper>

          </a>
          <a href="https://bnb-fashion.care4edu.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <Paper elevation={24} data-aos="zoom-in-up">
              <img src={bnb} style={{ width: 100, height: 100, objectFit: 'contain' }} alt="bnb" />
              <Typography style={{ color: "#BE2CC2", fontWeight:"bold" }}>BNB</Typography>

            </Paper>
          </a>
          <a href="https://asat.care4edu.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <Paper elevation={24} data-aos="zoom-in-down">
              <img src={asat} style={{ width: 100, height: 100, objectFit: 'contain' }} alt="asat" />
              <Typography style={{ color: "#F0831C", fontWeight:"bold" }}>ASAT</Typography>

            </Paper>

          </a>
          <a href="https://avinya.care4edu.com/#/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <Paper elevation={24} data-aos="zoom-in-up">
              <img src={avinya} style={{ width: 100, height: 100, objectFit: 'contain' }} alt="avinya" />
              <Typography style={{ color: "#34A22E", fontWeight:"bold" }}>Avinya</Typography>

            </Paper>

          </a>
        </Box>
        <ContactUs/>
        <Footer/>
      </div>
     
    </>

  );
}