import React from 'react';
import { Grid, Typography, Link, Box, Container, List, ListItem } from '@mui/material';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
 
const Footer = () => {
    return (
        <Box sx={{ backgroundColor: '#0A1929', color: 'white', py: 2 }}>
            <Container>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ fontFamily: "Roboto Slab", fontWeight: "bold", mb: 2 }}>
                            Services
                        </Typography>
                        <Box>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                MuleSoft
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                Spring MicroServices                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                DevOps Automation                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                Application Development
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                Cloud Computing
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                Web Application Development  
                             </Typography>
 
                        </Box>
 
                    </Grid>
 
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ fontFamily: "Roboto Slab", fontWeight: "bold", mb: 2 }}>
                            Contact Us
                        </Typography>
                        <Box>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                CARE 4 EDU SOLUTIONS PVT LTD
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
                                Doddabommasandra Post, Vidyaranyapura
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 3 }}>
                                Bengaluru – 560097
                            </Typography>
 
                        </Box>
                        <Box >
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
 
                                <Link href="https://wa.me/+919535352376" target="_blank" sx={{ color: 'white', textDecoration: 'none', fontFamily: "Roboto Slab", mb: 2 }}>
                                    <IoLogoWhatsapp style={{ marginRight: '8px' }} /> +91 95353 52376
                                </Link>
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
 
                                <Link href="mailto:info@care4edu.com" target="_blank" sx={{ color: 'white', textDecoration: 'none', fontFamily: "Roboto Slab", mb: 2 }}>
                                    <FaEnvelope style={{ marginRight: '8px' }} /> info@care4edu.com
                                </Link>
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto Slab", fontSize: '1rem', mb: 1 }}>
 
                                <Link href="https://www.google.com/maps?q=13.059230794323861,77.56238402713825" target="_blank" sx={{ color: 'white', textDecoration: 'none', fontFamily: "Roboto Slab" }}>
                                    <FaMapMarkerAlt style={{ marginRight: '8px' }} /> Vidyaranyapura Office
                                </Link>
                            </Typography>
 
                        </Box>
                    </Grid>
 
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ fontFamily: "Roboto Slab", fontWeight: "bold", mb: 2 }}>
                            Follow Us
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                           
                            <Link href="#" sx={{ color: 'white', textDecoration: 'none', mb: 1 }}>
                            <FaLinkedin style={{ marginRight: '8px' }} /> LinkedIn
                            </Link>
                            <Link href="https://www.instagram.com/care4_edu?igsh=MXBud2UyNGVhYWlyNA==" target="_blank" sx={{ color: 'white', textDecoration: 'none', mb: 1 }}>
                            <FaSquareInstagram style={{ marginRight: '8px' }} /> Instagram
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Typography variant="body2" sx={{ fontFamily: "Roboto Slab", fontSize: '0.875rem' }}>
                        Copyright © 2021 Care 4 Edu Solutions Pvt Ltd - All Rights Reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
 
export default Footer;